<template>
  <v-card>
    <v-card-title>Datos del Motivo</v-card-title>
    <v-container v-if="error">
      <v-row>
        <v-col>
          <v-alert dense type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="nombre"
              :counter="150"
              label="Nombre"
              required
              :rules="nombreRules"
              @input="nombre = nombre.toUpperCase()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="codigo"
              :counter="20"
              label="Código"
              required
              :rules="codigoRules"
              @input="codigo = codigo.toUpperCase()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="codigoExterno"
              :counter="50"
              label="Código Externo"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-switch
              v-model="estado"
              :label="estado ? 'Activado: Sí' : 'Activado: No'"
            ></v-switch>
          </v-col>
          <v-col cols="12" md="3">
            <v-switch
              v-model="disponibleLicencias"
              :label="
                disponibleLicencias
                  ? 'Disponible Licencias: Sí'
                  : 'Disponible Licencias: No'
              "
            ></v-switch>
          </v-col>
          <v-col cols="12" md="3">
            <v-switch
              v-model="disponibleCasos"
              :label="
                disponibleCasos
                  ? 'Disponible Casos: Sí'
                  : 'Disponible Casos: No'
              "
            ></v-switch>
          </v-col>
          <v-col cols="12" md="3">
            <v-switch
              v-model="disponibleExtras"
              :label="
                disponibleExtras
                  ? 'Disponible Extras: Sí'
                  : 'Disponible Extras: No'
              "
            ></v-switch>
          </v-col>
        </v-row>

        <v-divider> </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loadingSave"
            @click="onSubmit()"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn color="error" to="/novedades/motivos"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import PresentismoService from "../services/PresentismoService";

export default {
  name: "NovedadesMotivoForm",
  components: {},

  data() {
    return {
      formValidity: false,
      nombre: "",
      codigo: null,
      codigoExterno: null,
      estado: true,
      disponibleLicencias: false,
      disponibleCasos: false,
      disponibleExtras: false,
      error: false,
      loadingSave: false,
      nombreRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 150 ||
          "La longuitud del campo nombre debe ser menor o igual a 150 caracteres.",
      ],
      codigoRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 20 ||
          "La longuitud del campo código debe ser menor o igual a 20 caracteres.",
      ],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
    };
  },
  props: {
    motivo: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.motivo) {
      this.nombre = this.motivo.nombre;
      this.codigo = this.motivo.codigo;
      this.codigoExterno = this.motivo.codigo_externo;
      this.disponibleLicencias = this.motivo.disponible_licencias;
      this.disponibleCasos = this.motivo.disponible_casos;
      this.disponibleExtras = this.motivo.disponible_extras;
      this.estado = this.motivo.estado;
    }
  },

  methods: {
    onSubmit() {
      this.loadingSave = true;

      if (
        !this.disponibleCasos &&
        !this.disponibleLicencias &&
        !this.disponibleExtras
      ) {
        this.error = "El motivo debe estar disponible para al menos un uso.";
        this.loadingSave = false;
        return;
      }

      let motivoInstance = {
        nombre: this.nombre,
        codigo: this.codigo,
        codigo_externo: this.codigoExterno,
        disponible_licencias: this.disponibleLicencias,
        disponible_casos: this.disponibleCasos,
        disponible_extras: this.disponibleExtras,
        estado: this.estado,
      };

      if (this.motivo) {
        motivoInstance.id = this.motivo.id;
        PresentismoService.putNovedadesMotivo(motivoInstance)
          .then((response) => {
            this.$router.push({
              name: "NovedadesMotivoDetalle",
              params: { id: motivoInstance.id },
            });
          })
          .catch((error) => {
            this.error = error.response.data;
            this.loadingSave = false;
          });
      } else {
        PresentismoService.postNovedadesMotivo(motivoInstance)
          .then((response) => {
            this.$router.push({
              name: "NovedadesMotivos",
            });
          })
          .catch((error) => {
            this.error = error;
            this.loadingSave = false;
          });
      }
    },

    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
<style>
.overlay {
  position: absolute;
  left: 100%;
  transform: translateX(-96%) !important;
  height: 100%;
  width: auto;
  display: flex;
}
</style>
